import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import {
  Text,
  TextKeyValuePair,
  TextWithIcon,
  Tooltip
} from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { handleOnClickZendesk } from '@/helpers/zendeskTicket';
import { convertToStandardMobileNumberFormat } from '@/lib/mobileNumberValidation';
import { getFormattedDate } from '@/lib/time';
import { parseNumberedLabel } from '@/lib/utils';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const EPICEnhancedProgress = ({
  entityId,
  epicEnhancedProgressPercentage
}) => {
  const tooltipConfigList = [
    {
      target: `epic-enhanced-percentage-${entityId}`,
      text: (
        <TextKeyValuePair
          {...{
            className: '',
            label: 'EPIC Enhanced',
            labelClass: '',
            spaceTop: '',
            value: `${epicEnhancedProgressPercentage} %`,
            valueClassName: 'bg-warning-dark px-1'
          }}
        />
      )
    }
  ];
  return (
    <>
      <Image
        alt='epic enhancement progress yellow icon'
        className='cursor-pointer'
        height={0}
        id={`epic-enhanced-percentage-${entityId}`}
        src={`${staticMediaStoreBaseURL}/icons/epic-enhancement-progress-yellow.svg`}
        style={{ height: 24, width: 24 }}
        width={0}
      />
      <Tooltip {...{ configList: tooltipConfigList }} />
    </>
  );
};

const ActionCenterUserEventCardWeb = ({
  hit: {
    entityId,
    epicEnhancedProgressPercentage,
    eventCity,
    eventDate,
    hostCreditEntityId,
    hostCreditEntityName,
    hostMobile,
    userEventName,
    userEventNumber,
    zendeskTicketNumber
  }
}) => {
  const router = useRouter();

  const tooltipConfigList = [
    {
      target: `event-date-${entityId}`,
      text: 'Event Date'
    },
    {
      target: `event-title-${entityId}`,
      text: 'Event Title'
    }
  ];

  const userEventCardDetails = [
    {
      icon: 'zendesk-with-frame.svg',
      id: `zendesk-ticket-number-${entityId}`,
      label: parseNumberedLabel(zendeskTicketNumber),
      labelStyle: 'text-sm font-light cursor-pointer hover:!text-brand',
      onClick: () => {
        zendeskTicketNumber && handleOnClickZendesk(zendeskTicketNumber);
      },
      onHoverCopy: true,
      tooltipText: 'Zendesk Ticket Number'
    },
    {
      icon: 'phone-brand-icon.svg',
      id: `host-mobile-number-${entityId}`,
      label: convertToStandardMobileNumberFormat(hostMobile),
      labelStyle: 'text-sm font-light',
      onHoverCopy: true,
      tooltipText: 'Host Mobile Number'
    },
    {
      icon: 'location-brand-icon.svg',
      id: `event-city-${entityId}`,
      label: eventCity,
      labelStyle: 'text-sm font-light',
      onHoverCopy: true,
      tooltipText: 'Event Location(City)'
    },
    {
      icon: 'host-credit-entity-brand-icon.svg',
      id: `host-credit-entity-name-${entityId}`,
      label: hostCreditEntityName,
      labelStyle: `text-sm font-light ${hostCreditEntityId ? 'cursor-pointer hover:!text-brand' : ''}`,
      onClick: () => {
        hostCreditEntityId &&
          router.push(
            getPageURL({
              pageName: PAGE_NAME.HOST_CREDIT_ENTITY_HOST_LIST.label,
              pathParams: {
                hostCreditEntityId
              }
            })
          );
      },
      onHoverCopy: true,
      tooltipText: 'Host Credit Entity Name'
    }
  ];

  const showEpicEnhancedProgress = epicEnhancedProgressPercentage < 100;

  return (
    <Link
      href={getPageURL({
        pageName: PAGE_NAME.EVENT_CART_LIST.label,
        pathParams: { userEventId: entityId }
      })}
      target='_blank'
    >
      <div className='flex gap-2 border border-neutral hover:border-brand shadow-navigate-cart rounded-lg pl-2'>
        <Text
          {...{
            className:
              'bg-brand-gradient text-white font-semibold w-14 h-14 rounded flex text-center items-center my-2 p-2',
            content: getFormattedDate({ date: eventDate, format: 'Do MMM' }),
            id: `event-date-${entityId}`
          }}
        />
        <div className='flex flex-col gap-2 py-2'>
          <Text
            {...{
              className: 'font-medium truncate',
              content: userEventName,
              id: `event-title-${entityId}`
            }}
          />
          <div className='flex gap-4'>
            {userEventCardDetails.map((userEventCardDetail, index) => (
              <TextWithIcon
                key={`${userEventCardDetail.id} _${index}`}
                {...{
                  ...userEventCardDetail,
                  className: 'shadow-none',
                  iconHeight: 20,
                  iconWidth: 20
                }}
              />
            ))}
          </div>
        </div>
        <div className='flex gap-4 ml-auto self-start items-baseline'>
          {showEpicEnhancedProgress && (
            <EPICEnhancedProgress
              {...{ entityId, epicEnhancedProgressPercentage }}
            />
          )}
          <TextWithIcon
            {...{
              alt: 'user event white icon',
              className:
                'shadow-none bg-brand-gradient py-1 px-3 rounded-bl-lg rounded-tr-lg',
              defaultCopyIcon: 'copy-white-icon',
              icon: 'user-event-white-icon.svg',
              iconHeight: 20,
              iconWidth: 20,
              id: `event-number-${entityId}`,
              label: parseNumberedLabel(userEventNumber),
              labelStyle: 'text-sm font-medium text-white',
              onClick: () => {
                router.push(
                  getPageURL({
                    pageName: PAGE_NAME.HOST_EVENT_PROFILE.label,
                    pathParams: {
                      userEventId: entityId
                    }
                  })
                );
              },
              onHoverCopy: true,
              tooltipText: 'User Event Number'
            }}
          />
        </div>
      </div>
      <Tooltip {...{ configList: tooltipConfigList }} />
    </Link>
  );
};

export default ActionCenterUserEventCardWeb;
