import Image from 'next/image';
import { useRouter } from 'next/router';

import { Text, TextWithIcon, Tooltip } from '@/components/atomic/nuclei';
import { CART_STATUS, mediaStoreBaseURL } from '@/config/common';
import { handleOnClickZendesk } from '@/helpers/zendeskTicket';
import { convertToShortMonthDateFormat, formatTimeToAmPm } from '@/lib/time';
import { parseNumberedLabel } from '@/lib/utils';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const UserEventAddress = ({
  additionalLabelStyle = '',
  alt,
  icon,
  id,
  label,
  onClick = () => {},
  tooltipText
}) => (
  <TextWithIcon
    {...{
      alt,
      className: 'flex',
      icon,
      iconHeight: 20,
      iconWidth: 20,
      id,
      label,
      labelStyle: `flex self-center text-sm md:text-base font-light text-nero max-w-52 truncate ${additionalLabelStyle}`,
      onClick,
      onHoverCopy: true,
      tooltipText
    }}
  />
);

const UserEventInfo = ({
  eventContactName,
  eventDate,
  eventStartTime,
  hostId,
  zendeskTicketId
}) => {
  const router = useRouter();
  const zendeskTicketIdLabel = zendeskTicketId || 'N/A';
  const eventDateAndTime = `${convertToShortMonthDateFormat(
    eventDate
  )}, ${formatTimeToAmPm(eventStartTime)}`;

  const cartUserEventInfo = [
    {
      additionalLabelStyle: 'cursor-pointer hover:text-brand',
      alt: 'zendesk',
      icon: 'zendesk-icon.svg',
      id: 'zendesk-ticket-number',
      label: parseNumberedLabel(zendeskTicketIdLabel),
      onClick: () => zendeskTicketId && handleOnClickZendesk(zendeskTicketId),
      tooltipText: 'Zendesk Ticket Number'
    },
    {
      additionalLabelStyle: 'cursor-pointer hover:text-brand',
      alt: 'user',
      icon: 'host-brand-v1-icon.svg',
      id: 'host-name',
      label: eventContactName,
      onClick: () =>
        router.push(
          getPageURL({
            pageName: PAGE_NAME.HOST_EVENT_LIST.label,
            pathParams: {
              userId: hostId
            }
          })
        ),
      tooltipText: 'Host Name'
    },
    {
      alt: 'calender',
      icon: 'date-brand-icon.svg',
      id: 'event-date-time',
      label: eventDateAndTime,
      tooltipText: 'Event Date & Time'
    }
  ];

  return cartUserEventInfo.map(
    (
      {
        additionalLabelStyle,
        alt,
        icon,
        id,
        label,
        onClick = () => {},
        tooltipText
      },
      key
    ) => (
      <UserEventAddress
        key={key}
        {...{
          additionalLabelStyle,
          alt,
          icon,
          id,
          label,
          onClick,
          tooltipText
        }}
      />
    )
  );
};

const CartInfo = ({ cartNumber, status }) => (
  <>
    <Text
      {...{
        className: `py-1 px-2 rounded text-sm font-medium ${
          status === CART_STATUS.DRAFT.value
            ? 'bg-gray text-dim-gray'
            : 'bg-greenlight text-green-base'
        }`,
        content: status,
        id: 'cart-status'
      }}
    />
    {/* TODO: redirection is pending */}
    <TextWithIcon
      {...{
        className: 'py-1 px-2 border border-neutral rounded',
        icon: 'cart-brand-icon-v1.svg',
        id: 'cart-number',
        label: parseNumberedLabel(cartNumber),
        labelStyle: 'text-dim-gray text-sm font-medium',
        onHoverCopy: true,
        tooltipText: 'Cart Number'
      }}
    />
  </>
);

const TenderCartUserEventInfo = ({
  cart,
  hostId,
  userEventDetail,
  zendeskTicketId
}) => {
  const {
    checkoutEvent: { iconUrl, event: { name: eventName } = {} },
    eventAddress: { formattedAddress },
    eventContactName,
    eventDate,
    eventStartTime
  } = userEventDetail;
  const { cartNumber, status } = cart;

  const tooltipConfigList = [
    {
      target: 'cart-status',
      text: 'Cart Status'
    }
  ];

  return (
    <div className='flex shadow-card rounded-2.5 h-36'>
      <Image
        alt='event image'
        className='rounded-l-2.5'
        height={0}
        priority={true}
        src={`${mediaStoreBaseURL}/${iconUrl}`}
        style={{ height: 144, width: 144 }}
        unoptimized={true}
        width={0}
      />
      <div className='flex flex-col gap-4 pl-6 py-5'>
        <Text
          {...{
            className: 'text-xl font-medium',
            content: eventName || 'N/A'
          }}
        />
        <div className='flex gap-8 text-base font-medium text-dim-gray'>
          <UserEventInfo
            {...{
              eventContactName,
              eventDate,
              eventStartTime,
              hostId,
              zendeskTicketId
            }}
          />
        </div>
        <UserEventAddress
          {...{
            alt: 'location',
            icon: 'location-icon.svg',
            id: 'event-location',
            label: formattedAddress,
            onHoverCopy: true,
            tooltipText: 'Event Location'
          }}
        />
      </div>
      <div className='flex justify-end flex-1 gap-4 self-baseline font-medium py-5 px-6'>
        <CartInfo {...{ cartNumber, status }} />
      </div>
      <Tooltip {...{ configList: tooltipConfigList }} />
    </div>
  );
};

export default TenderCartUserEventInfo;
