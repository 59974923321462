import Link from 'next/link';
import { useRouter } from 'next/router';

import {
  Text,
  TextKeyValuePair,
  TextWithIcon,
  Tooltip
} from '@/components/atomic/nuclei';
import { DEFAULT_HITS_PER_PAGE_FOR_PAYMENT_PENDING_EXPIRED_USER_EVENT } from '@/config/searchkit/actionCenter';
import {
  convertToCurrencyFormat,
  parseFormatPriceValueFromAPI
} from '@/lib/numberStringUtils';
import { useIsMobile } from '@/lib/screenResolution';
import { getFormattedDate } from '@/lib/time';
import { parseNumberedLabel } from '@/lib/utils';
import { openPaymentPendingExpiredUserEvents } from '@/services/actionCenter.service';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const PaymentPendingExpiredUserEventCard = ({
  hit: {
    __position: hitIndex,
    entityId,
    eventDate,
    userEventName,
    userEventNumber,
    userEventPaymentDueAmount,
    userEventPaymentPendingOrderCount
  },
  selectedPlannerId
}) => {
  const router = useRouter();
  const showOverlay =
    hitIndex === DEFAULT_HITS_PER_PAGE_FOR_PAYMENT_PENDING_EXPIRED_USER_EVENT;

  const tooltipConfigList = [
    {
      target: `event-date-${entityId}`,
      text: 'Event Date'
    },
    {
      target: `event-title-${entityId}`,
      text: 'Event Title'
    }
  ];

  const paymentPendingExpiredUserEventCardDetails = [
    {
      alt: 'user event brand icon',
      className: 'rounded py-1 px-1',
      gap: 'gap-[5px]',
      icon: 'user-event-brand-icon.svg',
      id: `event-number-${entityId}`,
      label: parseNumberedLabel(userEventNumber),
      onClick: () => {
        router.push(
          getPageURL({
            pageName: PAGE_NAME.HOST_EVENT_PROFILE.label,
            pathParams: {
              userEventId: entityId
            }
          })
        );
      },
      onHoverCopy: true,
      tooltipPositionClass: 'bottom',
      tooltipText: 'Event Number'
    },
    {
      alt: 'price icon',
      className: 'rounded py-1 px-1',
      gap: 'gap-[5px]',
      icon: 'price-aed-brand-icon.svg',
      id: `amount-due-${entityId}`,
      label: convertToCurrencyFormat({
        roundOff: true,
        value: parseFormatPriceValueFromAPI(userEventPaymentDueAmount)
      }),
      onHoverCopy: true,
      tooltipPositionClass: 'bottom',
      tooltipText: 'Amount Due'
    }
  ];
  const [isMobile] = useIsMobile();

  return (
    <div className='relative'>
      {showOverlay && (
        <div className='absolute inset-0 bg-black bg-opacity-50 rounded-lg flex items-center justify-center'>
          <Text
            {...{
              className: 'font-semibold text-white cursor-pointer',
              content: 'View All',
              onClick: () =>
                openPaymentPendingExpiredUserEvents({
                  selectedPlannerId
                })
            }}
          />
        </div>
      )}
      <Link
        href={getPageURL({
          pageName: PAGE_NAME.EVENT_CART_LIST.label,
          pathParams: { userEventId: entityId }
        })}
        target='_blank'
      >
        <div
          className={`flex flex-col md:flex-row gap-3 border border-neutral hover:border-brand w-[300px] md:w-[440px] shadow-navigate-cart rounded-lg md:p-3 p-2 pb-1`}
        >
          <TextKeyValuePair
            {...{
              className: 'flex gap-2 md:gap-4',
              label: getFormattedDate({
                date: eventDate,
                format: 'Do MMM'
              }),
              labelClass: `bg-[#F26E271F] text-brand font-semibold w-[40px] md:w-[64px] h-[40px] md:h-[64px] rounded flex text-center items-center px-2 py-2 md:py-0 text-xs md:text-base`,
              labelId: `event-date-${entityId}`,
              spaceTop: '',
              value: userEventName,
              valueClassName:
                'font-medium text-xs flex md:hidden truncate-text leading-0 self-center',
              valueId: `event-title-${entityId}`
            }}
          />
          <div className='flex flex-col gap-3 flex-1 md:self-center'>
            <Text
              {...{
                className: 'font-medium text-xs hidden md:flex',
                content: userEventName,
                id: `event-title-${entityId}`
              }}
            />
            <div className='flex gap-4 justify-between items-center'>
              <div className='flex gap-1'>
                {paymentPendingExpiredUserEventCardDetails.map(
                  (paymentPendingExpiredUserEventCardDetail, index) => (
                    <TextWithIcon
                      key={`${paymentPendingExpiredUserEventCardDetail.id} _${index}`}
                      {...{
                        ...paymentPendingExpiredUserEventCardDetail,
                        iconHeight: isMobile ? 12 : 16,
                        iconWidth: isMobile ? 12 : 16,
                        labelStyle:
                          'text-[10px] md:text-sm font-medium text-dim-gray cursor-pointer hover:!text-brand'
                      }}
                    />
                  )
                )}
              </div>
              <TextKeyValuePair
                {...{
                  className:
                    'text-[10px] md:text-sm font-medium rounded-lg py-[2px] md:py-1 px-1 md:px-2 shadow-card items-center',
                  label: userEventPaymentPendingOrderCount,
                  labelClass: `bg-brand-gradient text-white rounded-full md:min-w-5 md:h-5 min-w-4 h-4 text-[8px] md:text-sm px-1 items-center font-medium justify-center flex`,
                  spaceTop: '',
                  value: 'Orders',
                  valueClassName:
                    'text-[10px] md:text-sm font-medium text-dim-gray'
                }}
              />
            </div>
          </div>
          <Tooltip {...{ configList: tooltipConfigList }} />
        </div>
      </Link>
    </div>
  );
};

export default PaymentPendingExpiredUserEventCard;
