import { useRouter } from 'next/router';

import { Text, TextWithIcon, Tooltip } from '@/components/atomic/nuclei';
import { getUserEventCartDetails } from '@/services/cart.plan.service';

const CartEventInfo = ({ cartDetails, userEventSummary }) => {
  const router = useRouter();

  const { userEventDetails: [userEventDetails] = [] } = userEventSummary || {};
  const { eventTitle } = userEventDetails;
  const { userEventDetailsBody } = getUserEventCartDetails({
    router,
    userCartDetails: cartDetails
  });

  const tooltipConfigList = [
    {
      target: 'event-name',
      text: 'Event Name'
    }
  ];

  return (
    <div className='p-5 bg-lightgray rounded-b-md'>
      <div className='flex flex-col md:flex-row justify-start gap-4'>
        <Text
          className='font-semibold text-nero'
          content={eventTitle || 'NA'}
          id='event-name'
        />
      </div>
      <div className='flex flex-col md:flex-row justify-between bg-lightgray mt-4'>
        <div className='grid grid-cols-1 md:grid-cols-4 md:grid-rows-2 gap-2 mt-2 md:mt-0'>
          {userEventDetailsBody.map(
            (
              {
                additionalLabelStyle = '',
                alt,
                className,
                icon,
                id,
                onClick = () => {},
                onHoverCopy,
                tooltipText,
                value
              },
              index
            ) => (
              <div
                className={`flex gap-3 md:gap-2 md:w-96 ${className}`}
                key={`${id}_${index}`}
              >
                <TextWithIcon
                  {...{
                    alt,
                    className: 'flex',
                    icon,
                    iconHeight: 20,
                    iconWidth: 20,
                    id,
                    label: value,
                    labelStyle: `flex self-center text-sm md:text-base font-light text-nero max-w-52 truncate ${additionalLabelStyle}`,
                    onClick,
                    onHoverCopy,
                    tooltipText
                  }}
                />
              </div>
            )
          )}
        </div>
      </div>
      <Tooltip {...{ configList: tooltipConfigList }} />
    </div>
  );
};

export default CartEventInfo;
