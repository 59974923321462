import { ALLOCATION_STATUS, USER_EVENT_STAGE } from 'config/common';
import { getUpcomingAssignedUserEventsAPI } from 'services/actionCenter.service';

const LIMIT = 10;

export const fetchNewAssignedUserEvents = async ({
  plannerId,
  setHasMore,
  setLoading,
  setShowToast,
  setSkip,
  setUpcomingAssignedUserEventsCount,
  setUpcomingAssignedUserEventsInfo,
  skip
}) => {
  setLoading(skip === 0);
  const { entity, message, status } = await getUpcomingAssignedUserEventsAPI({
    limit: LIMIT,
    plannerId,
    skip
  });

  const { upcomingAssignedUserEvents, upcomingAssignedUserEventsCount } =
    entity;
  setUpcomingAssignedUserEventsInfo((prevItems) => [
    ...prevItems,
    ...upcomingAssignedUserEvents
  ]);
  setUpcomingAssignedUserEventsCount(upcomingAssignedUserEventsCount);
  if (upcomingAssignedUserEvents.length < LIMIT) {
    setHasMore(false);
  }
  setSkip((prevSkip) => prevSkip + LIMIT);
  setShowToast({ message, show: !status, successToast: status });
  setLoading(false);
};

const USER_EVENT_ACTION_STATUS_LABEL = {
  [ALLOCATION_STATUS.EVENT_ACCEPTED.value]: { label: 'Accepted' },
  [ALLOCATION_STATUS.REASSIGN_REQUESTED.value]: { label: 'Requested' }
};

export const getLabelsBasedOnAllocationStatusAndEPICProgress = ({
  allocationStatus,
  epicGenerationProgressPercentage,
  stage
}) => {
  const labels = [];

  const statusLabel = USER_EVENT_ACTION_STATUS_LABEL[allocationStatus]
    ?.label && {
    className: `text-xs font-medium px-2 rounded self-center px-2 py-1 md:py-[2px] w-fit ${ALLOCATION_STATUS[allocationStatus].className}`,
    content: USER_EVENT_ACTION_STATUS_LABEL[allocationStatus].label
  };

  const epicLabel = {
    className:
      'flex px-2 py-1 rounded text-white bg-brand-gradient font-medium text-xs md:items-center',
    content: `EPIC ${epicGenerationProgressPercentage}%`
  };

  const conditions = [
    {
      check:
        stage === USER_EVENT_STAGE.EVENT_CREATED.value &&
        allocationStatus === ALLOCATION_STATUS.REASSIGN_REQUESTED.value,
      labelsToAdd: [statusLabel, epicLabel]
    },
    {
      check:
        stage === USER_EVENT_STAGE.EVENT_CREATED.value &&
        allocationStatus === ALLOCATION_STATUS.EVENT_ACCEPTED.value,
      labelsToAdd: [statusLabel, epicLabel]
    },
    {
      check:
        stage === USER_EVENT_STAGE.EVENT_ASSIGNED.value &&
        allocationStatus === ALLOCATION_STATUS.REASSIGN_REQUESTED.value,
      labelsToAdd: [statusLabel]
    }
  ];

  labels.push(
    ...conditions
      .filter(({ check }) => check)
      .flatMap(({ labelsToAdd }) => labelsToAdd.filter(Boolean))
  );

  return labels;
};

export const shouldShowAcceptReassignCTA = ({ allocationStatus, stage }) =>
  [
    USER_EVENT_STAGE.EVENT_CREATED.value,
    USER_EVENT_STAGE.EVENT_ASSIGNED.value
  ].includes(stage) &&
  [
    ALLOCATION_STATUS.ACKNOWLEDGEMENT_PENDING.value,
    ALLOCATION_STATUS.NOT_READY_FOR_ALLOCATION.value,
    ALLOCATION_STATUS.READY_FOR_ALLOCATION.value
  ].includes(allocationStatus);
